<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
      />
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import {
  BCard
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      dataurl:"/contact",
      baseroute:"contact",
      title:"Contact",
      fields: [
        { key: 'name', label: 'name', sortable: false },
        { key: 'address', label: 'address', sortable: false },
        { key: 'city', label: 'city', sortable: false },
        { key: 'phone', label: 'phone', sortable: false }
      ],
    }
  },
}
</script>
<style lang="">

</style>
